import moment from 'moment'

export const projectName= "Goel Ganga"
export const serverErrorMessage= "Something Went Wrong!"

export const custTime= "9 AM"

export const selectableTimes= ["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM"]

export const scheduleConfUrl = 'public_api/leads'
export const bookingUrl = 'public/bookings/new'
export const userTypeUrl = 'user_type'
export const enquiryFormUrl = "customer"

export const paymentPlans = ['CLP', 'EMI Scheme', 'EMI+CLP', 'Subvention 1:99', 'Subvention 10:90']

export function confCallParams(callParams){
  let toSend = {}
  toSend['name'] = callParams.custName
  toSend['email'] = callParams.custEmail
  toSend['phone'] = callParams.custPhone
  toSend['date'] = callParams.custDate ? moment(callParams.custDate).format("DD-MM-YYYY") : null
  toSend['time'] = callParams.custTime
  toSend['project'] = projectName
  return toSend
}

export function santizeBookingParams(bParams){
  let toSend = {}
  if(bParams.leadNo){
    toSend['ln'] = bParams.leadNo
  }else{
    toSend['p'] = projectName
  }
  toSend['t'] = bParams.typologyName
  toSend['f'] = bParams.floorName
  toSend['in'] = bParams.flatName
  toSend['wi'] = bParams.wingName
  toSend['pn'] = bParams.selectedPlan
  toSend['cpuu'] = bParams.cpId
  return toSend
}

export const masterJson = 
{
  "Buildings" : [
    {
      "uuid": "1",
      "key": "Plot 28",
      "value": "Plot 28",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M158 353L137.5 349.5L124.5 372L151.5 378L158 353Z"
    },
    {
      "uuid": "2",
      "key": "Plot 29",
      "value": "Plot 29",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M158 353L176.5 357L170.5 382.5L151.5 378L158 353Z"
    },
    {
      "uuid": "3",
      "key": "Plot 30",
      "value": "Plot 30",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M195 361.5L176.5 357L170.5 382.5L189 387L195 361.5Z"
    },
    {
      "uuid": "4",
      "key": "Plot 31",
      "value": "Plot 31",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M195 361.5L215 365.5L208 391L189 387L195 361.5Z"
    },
    {
      "uuid": "5",
      "key": "Plot 32",
      "value": "Plot 32",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M231 390H209L214.867 365H231V390Z"
    },
    {
      "uuid": "6",
      "key": "Plot 33",
      "value": "Plot 33",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M254 390H231V365H254V390Z"
    },
    {
      "uuid": "7",
      "key": "Plot 34",
      "value": "Plot 34",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M276 390H254V365H276V390Z"
    },
    {
      "uuid": "8",
      "key": "Plot 35",
      "value": "Plot 35",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M298 391H276V365H298V391Z"
    },
    {
      "uuid": "9",
      "key": "Plot 36",
      "value": "Plot 36",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M298 415H276V391H298V415Z"
    },
    {
      "uuid": "10",
      "key": "Plot 37",
      "value": "Plot 37",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M276 415H253V391H276V415Z"
    },
    {
      "uuid": "11",
      "key": "Plot 38",
      "value": "Plot 38",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M253 415H231V391H253V415Z"
    },
    {
      "uuid": "12",
      "key": "Plot 39",
      "value": "Plot 39",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M231 415H202L208 391H231V415Z"
    },
    {
      "uuid": "13",
      "key": "Plot 40",
      "value": "Plot 40",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M183.5 411L202 415L208 391L189 387L183.5 411Z"
    },
    {
      "uuid": "14",
      "key": "Plot 41",
      "value": "Plot 41",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M183.5 411L164.5 407.5L170.5 382.5L189 387L183.5 411Z"
    },
    {
      "uuid": "15",
      "key": "Plot 42",
      "value": "Plot 42",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M146.5 403L164.5 407.5L170.5 382.5L151.5 378L146.5 403Z"
    },
    {
      "uuid": "16",
      "key": "Plot 43",
      "value": "Plot 43",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M146.5 403L106 393.5L124.5 372L151.5 378L146.5 403Z"
    },
    {
      "uuid": "17",
      "key": "Plot 44",
      "value": "Plot 44",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M109 431.5L70.5 422.5L92 402L114 407.5L109 431.5Z"
    },
    {
      "uuid": "18",
      "key": "Plot 45",
      "value": "Plot 45",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M108.5 433L133 440L138.5 413.5L114 407.5L108.5 433Z"
    },
    {
      "uuid": "19",
      "key": "Plot 46",
      "value": "Plot 46",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M108.5 432L102.5 461L80 447L59.5 438.5H36.5L70.5 422L108.5 432Z"
    },
    {
      "uuid": "20",
      "key": "Plot 47",
      "value": "Plot 47",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M108.5 433L133 440L124.5 474.5L103 461.5L108.5 433Z"
    },
    {
      "uuid": "21",
      "key": "Plot 48",
      "value": "Plot 48",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M138.5 450L152.5 452.5L161.5 499.5L133 479.5L138.5 450Z"
    },
    {
      "uuid": "22",
      "key": "Plot 49",
      "value": "Plot 49",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,358",
      "path": "M161 453.5L138.5 450L146.5 415L168.5 420.5L161 453.5Z"
    },
    {
      "uuid": "23",
      "key": "Plot 50",
      "value": "Plot 50",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,478",
      "path": "M181 456.5L161 453L168.5 420.5L188 425L181 456.5Z"
    },
    {
      "uuid": "24",
      "key": "Plot 51",
      "value": "Plot 51",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,314",
      "path": "M202 459.5L181.5 457L188.5 425L198.5 427.5H208.5L202 459.5Z"
    },
    {
      "uuid": "25",
      "key": "Plot 52",
      "value": "Plot 52",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,366",
      "path": "M225 462.5L202 459.5L208.5 427.5L225 428V462.5Z"
    },
    {
      "uuid": "26",
      "key": "Plot 53",
      "value": "Plot 53",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,016",
      "path": "M246 468L225.5 463L225 428H246V468Z"
    },
    {
      "uuid": "27",
      "key": "Plot 54",
      "value": "Plot 54",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,915",
      "path": "M266 475L245 467.5V428H266V475Z"
    },
    {
      "uuid": "28",
      "key": "Plot 55",
      "value": "Plot 55",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M266 475L298 486.5V461H281.66H266V475Z"
    },
    {
      "uuid": "29",
      "key": "Plot 56",
      "value": "Plot 56",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,956",
      "path": "M266 461H298V428H281.66H266V461Z"
    },
    {
      "uuid": "30",
      "key": "Plot 57",
      "value": "Plot 57",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "6,968",
      "path": "M309 391H351V365H329.553H309V391Z"
    },
    {
      "uuid": "31",
      "key": "Plot 58",
      "value": "Plot 58",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "6,975",
      "path": "M309 417H351V391H329.553H309V417Z"
    },
    {
      "uuid": "32",
      "key": "Plot 59",
      "value": "Plot 59",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "6,949",
      "path": "M309 443H351V417H329.553H309V443Z"
    },
    {
      "uuid": "33",
      "key": "Plot 60",
      "value": "Plot 60",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,053",
      "path": "M309 469H338V443H323.191H309V469Z"
    },
    {
      "uuid": "34",
      "key": "Plot 61",
      "value": "Plot 61",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M351 391H391V365H370.574H351V391Z"
    },
    {
      "uuid": "35",
      "key": "Plot 62",
      "value": "Plot 62",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "6,975",
      "path": "M351 417H391V391H370.574H351V417Z"
    },
    {
      "uuid": "36",
      "key": "Plot 63",
      "value": "Plot 63",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "6,949",
      "path": "M351 443H391V417H370.574H351V443Z"
    },
    {
      "uuid": "37",
      "key": "Plot 64",
      "value": "Plot 64",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,278",
      "path": "M338 469H367V443H352.191H338V469Z"
    },
    {
      "uuid": "38",
      "key": "Plot 65A",
      "value": "Plot 65A",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M367 456H391V443H378.745H367V456Z"
    },
    {
      "uuid": "39",
      "key": "Plot 65B",
      "value": "Plot 65B",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M367 469H391V456H378.745H367V469Z"
    },
    {
      "uuid": "40",
      "key": "Plot 66",
      "value": "Plot 66",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,701",
      "path": "M309 487H338V469H323.191H309V487Z"
    },
    {
      "uuid": "41",
      "key": "Plot 67",
      "value": "Plot 67",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,278",
      "path": "M338 487H367V469H352.191H338V487Z"
    },
    {
      "uuid": "42",
      "key": "Plot 68",
      "value": "Plot 68",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,283",
      "path": "M367 487H391V469H378.745H367V487Z"
    },
    {
      "uuid": "43",
      "key": "Plot 69",
      "value": "Plot 69",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M312.5 541H352V509H326.468H302L298.5 511.5L312.5 541Z"
    },
    {
      "uuid": "44",
      "key": "Plot 70",
      "value": "Plot 70",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M352 541H391V509H371.085H352V541Z"
    },
    {
      "uuid": "45",
      "key": "Plot 71",
      "value": "Plot 71",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M312.5 541H357V599.5L347.5 601.5L328.5 578.5L312.5 541Z"
    },
    {
      "uuid": "46",
      "key": "Plot 72",
      "value": "Plot 72",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M357 563H391V541H373.638H357V563Z"
    },
    {
      "uuid": "47",
      "key": "Plot 73",
      "value": "Plot 73",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M357 563H391V541V583L357 589V563Z"
    },
    {
      "uuid": "48",
      "key": "Plot 74",
      "value": "Plot 74",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M429 324H407V300H429V324Z"
    },
    {
      "uuid": "49",
      "key": "Plot 75",
      "value": "Plot 75",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,524",
      "path": "M429 324H453V300H440.745H429V324Z"
    },
    {
      "uuid": "50",
      "key": "Plot 76",
      "value": "Plot 76",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,655",
      "path": "M453 324H476V300H464.255H453V324Z"
    },
    {
      "uuid": "51",
      "key": "Plot 77",
      "value": "Plot 77",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,784",
      "path": "M476 324H499V300H487.255H476V324Z"
    },
    {
      "uuid": "52",
      "key": "Plot 78",
      "value": "Plot 78",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,629",
      "path": "M499 324H523V303.5L511 300H499V324Z"
    },
    {
      "uuid": "53",
      "key": "Plot 79",
      "value": "Plot 79",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,150",
      "path": "M523 326H549V304H535.723H523V326Z"
    },
    {
      "uuid": "54",
      "key": "Plot 80",
      "value": "Plot 80",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,151",
      "path": "M549 326H577V304H562.702H549V326Z"
    },
    {
      "uuid": "55",
      "key": "Plot 81",
      "value": "Plot 81",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,996",
      "path": "M549 348H577V326H562.702H549V348Z"
    },
    {
      "uuid": "56",
      "key": "Plot 82",
      "value": "Plot 82",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,800",
      "path": "M523 348H549V326H535.723H523V348Z"
    },
    {
      "uuid": "57",
      "key": "Plot 83",
      "value": "Plot 83",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,976",
      "path": "M499 348H523V324H510.745H499V348Z"
    },
    {
      "uuid": "58",
      "key": "Plot 84",
      "value": "Plot 84",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,875",
      "path": "M476 348H499V324H487.255H476V348Z"
    },
    {
      "uuid": "59",
      "key": "Plot 85",
      "value": "Plot 85",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,875",
      "path": "M453 348H476V324H464.255H453V348Z"
    },
    {
      "uuid": "60",
      "key": "Plot 86",
      "value": "Plot 86",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,875",
      "path": "M429 348H453V324H440.745H429V348Z"
    },
    {
      "uuid": "61",
      "key": "Plot 87",
      "value": "Plot 87",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,875",
      "path": "M407 348H429V324H417.766H407V348Z"
    },
    {
      "uuid": "62",
      "key": "Plot 88",
      "value": "Plot 88",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M429 406H407V365H429V406Z"
    },
    {
      "uuid": "63",
      "key": "Plot 89",
      "value": "Plot 89",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M451 406H429V365H451V406Z"
    },
    {
      "uuid": "64",
      "key": "Plot 90",
      "value": "Plot 90",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M474 406H451V365H474V406Z"
    },
    {
      "uuid": "65",
      "key": "Plot 91",
      "value": "Plot 91",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M497 406H474V365H497V406Z"
    },
    {
      "uuid": "66",
      "key": "Plot 92",
      "value": "Plot 92",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M517 406H497V365H517V406Z"
    },
    {
      "uuid": "67",
      "key": "Plot 93",
      "value": "Plot 93",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M553 398H529V365H553V398Z"
    },
    {
      "uuid": "68",
      "key": "Plot 94",
      "value": "Plot 94",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M553 417H529V398H553V417Z"
    },
    {
      "uuid": "69",
      "key": "Plot 95",
      "value": "Plot 95",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M553 438H529V417H553V438Z"
    },
    {
      "uuid": "70",
      "key": "Plot 96",
      "value": "Plot 96",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M553 456H529V438H553V456Z"
    },
    {
      "uuid": "71",
      "key": "Plot 97",
      "value": "Plot 97",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M553 476H529V456H553V476Z"
    },
    {
      "uuid": "72",
      "key": "Plot 98",
      "value": "Plot 98",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M551 496H529V476H566.5L551 496Z"
    },
    {
      "uuid": "73",
      "key": "Plot 99",
      "value": "Plot 99",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,468.11",
      "path": "M553 398H577V365H564.745H553V398Z"
    },
    {
      "uuid": "74",
      "key": "Plot 100",
      "value": "Plot 100",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M577 417H553V398H577V417Z"
    },
    {
      "uuid": "75",
      "key": "Plot 101",
      "value": "Plot 101",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M577 438H553V417H577V438Z"
    },
    {
      "uuid": "76",
      "key": "Plot 102",
      "value": "Plot 102",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M577 456H553V438H577V456Z"
    },
    {
      "uuid": "77",
      "key": "Plot 103",
      "value": "Plot 103",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,068",
      "path": "M577 476H553V456H577V476Z"
    },
    {
      "uuid": "78",
      "key": "Plot 111",
      "value": "Plot 111",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,973",
      "path": "M589 325H614V304H601.234H589V325Z"
    },
    {
      "uuid": "79",
      "key": "Plot 112",
      "value": "Plot 112",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,804",
      "path": "M614 325L643 324L634.5 301L626.234 304H614V325Z"
    },
    {
      "uuid": "80",
      "key": "Plot 113",
      "value": "Plot 113",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,977",
      "path": "M643.5 324.5L664.5 301L648 286L642 293L634.5 301.5L643.5 324.5Z"
    },
    {
      "uuid": "81",
      "key": "Plot 114",
      "value": "Plot 114",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M672.5 292.5L654.5 274L658.5 260L680.5 265.5L672.5 292.5Z"
    },
    {
      "uuid": "82",
      "key": "Plot 115",
      "value": "Plot 115",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M685.5 242.5L663 237.5L658.5 260L680.5 265.5L685.5 242.5Z"
    },
    {
      "uuid": "83",
      "key": "Plot 116",
      "value": "Plot 116",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M685.5 242.5L663 237.5L668.5 214L691 219.5L685.5 242.5Z"
    },
    {
      "uuid": "84",
      "key": "Plot 117",
      "value": "Plot 117",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M696 197.5L675 188L668.5 214L691 219.5L696 197.5Z"
    },
    {
      "uuid": "85",
      "key": "Plot 118",
      "value": "Plot 118",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M696 197.5L715.5 210L710 233L688.5 228L696 197.5Z"
    },
    {
      "uuid": "86",
      "key": "Plot 119",
      "value": "Plot 119",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M684 249.5L705.5 254L710 233L688.5 228L684 249.5Z"
    },
    {
      "uuid": "87",
      "key": "Plot 120",
      "value": "Plot 120",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M684 249.5L705.5 254L701 276L679.5 270.5L684 249.5Z"
    },
    {
      "uuid": "88",
      "key": "Plot 121",
      "value": "Plot 121",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,351",
      "path": "M672.5 292L690.5 308L696.5 301L701 276L679.5 270.5L672.5 292Z"
    },
    {
      "uuid": "89",
      "key": "Plot 122",
      "value": "Plot 122",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,716",
      "path": "M652.5 346.5L681 316L664.5 301L656.5 309.5L643 324.5L652.5 346.5Z"
    },
    {
      "uuid": "90",
      "key": "Plot 123",
      "value": "Plot 123",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,221",
      "path": "M614 348L652.5 347.5L643 324.5L626.234 325H614V348Z"
    },
    {
      "uuid": "91",
      "key": "Plot 124",
      "value": "Plot 124",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,973",
      "path": "M589 348H614V325H601.234H589V348Z"
    },
    {
      "uuid": "92",
      "key": "Plot 125",
      "value": "Plot 125",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M589 384H615V365H601.723H589V384Z"
    },
    {
      "uuid": "93",
      "key": "Plot 126",
      "value": "Plot 126",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M615 384H640V365H627.234H615V384Z"
    },
    {
      "uuid": "94",
      "key": "Plot 127",
      "value": "Plot 127",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,796.44",
      "path": "M640 384H668L680 372L665 359L659.5 365.5H640V384Z"
    },
    {
      "uuid": "95",
      "key": "Plot 128",
      "value": "Plot 128",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M665 359L679.5 343L693.5 356L680 372.5L665 359Z"
    },
    {
      "uuid": "96",
      "key": "Plot 129",
      "value": "Plot 129",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M693.5 356L707 340.5L693.5 327L686 336L679.5 343L693.5 356Z"
    },
    {
      "uuid": "97",
      "key": "Plot 130",
      "value": "Plot 130",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,415",
      "path": "M701.652 317.414L716.152 330.914L732.652 311.914L713.152 294.414L709.652 307.914L701.652 317.414Z"
    },
    {
      "uuid": "98",
      "key": "Plot 131",
      "value": "Plot 131",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M713.5 294L720 265.5L751 293L732.5 312L713.5 294Z"
    },
    {
      "uuid": "99",
      "key": "Plot 132",
      "value": "Plot 132",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M725 244.5L720 265.5L744.5 287.5L764.5 262.5L734.5 232L725 244.5Z"
    },
    {
      "uuid": "100",
      "key": "Plot 133",
      "value": "Plot 133",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M779 299L768.5 309L744.5 287.5L765 262.5L778 281.5L779 299Z"
    },
    {
      "uuid": "101",
      "key": "Plot 134",
      "value": "Plot 134",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M751 328L768.5 309L751 293L732.5 312L751 328Z"
    },
    {
      "uuid": "102",
      "key": "Plot 135",
      "value": "Plot 135",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "4,056",
      "path": "M716 330.5L733.5 348.5L751 328L741 319.5L732.5 312.5L716 330.5Z"
    },
    {
      "uuid": "103",
      "key": "Plot 136",
      "value": "Plot 136",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M725 356L711.5 371.5L693.5 356L707 340.5L725 356Z"
    },
    {
      "uuid": "104",
      "key": "Plot 137",
      "value": "Plot 137",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M697.5 387.5L711.5 371.5L693.5 356L680 372.5L697.5 387.5Z"
    },
    {
      "uuid": "105",
      "key": "Plot 138",
      "value": "Plot 138",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,441",
      "path": "M668 384.5L678.5 407.5L691 394.5L697.5 387.5L680 372L668 384.5Z"
    },
    {
      "uuid": "106",
      "key": "Plot 139",
      "value": "Plot 139",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,041",
      "path": "M640 408L678.5 407.5L668 384H652.234H640V408Z"
    },
    {
      "uuid": "107",
      "key": "Plot 140",
      "value": "Plot 140",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,875",
      "path": "M615 408H640V384H627.234H615V408Z"
    },
    {
      "uuid": "108",
      "key": "Plot 141",
      "value": "Plot 141",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "3,875",
      "path": "M589 408H615V384H601.723H589V408Z"
    },
    {
      "uuid": "109",
      "key": "Plot 142",
      "value": "Plot 142",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M589 440H617V419H602.702H589V440Z"
    },
    {
      "uuid": "110",
      "key": "Plot 143",
      "value": "Plot 143",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M617 440H645V419H630.702H617V440Z"
    },
    {
      "uuid": "111",
      "key": "Plot 144",
      "value": "Plot 144",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M645 440H673V419H658.702H645V440Z"
    },
    {
      "uuid": "112",
      "key": "Plot 145",
      "value": "Plot 145",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M673 419V439.5L702.5 439L713 428L693.5 410.5L685.5 419H673Z"
    },
    {
      "uuid": "113",
      "key": "Plot 146",
      "value": "Plot 146",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M707.5 394.5L727 412.5L713 428L693.5 410.5L707.5 394.5Z"
    },
    {
      "uuid": "114",
      "key": "Plot 147",
      "value": "Plot 147",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M707.5 394.5L727 412.5L740.5 397L721 379.5L707.5 394.5Z"
    },
    {
      "uuid": "115",
      "key": "Plot 148",
      "value": "Plot 148",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M733.5 365L753.5 383L740.5 397L721 379.5L733.5 365Z"
    },
    {
      "uuid": "116",
      "key": "Plot 149",
      "value": "Plot 149",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M762 373L742.5 355.5L762 334.5L782 352.5L762 373Z"
    },
    {
      "uuid": "117",
      "key": "Plot 150",
      "value": "Plot 150",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M802 330.5L786 311L762 334.5L782 352.5L802 330.5Z"
    },
    {
      "uuid": "118",
      "key": "Plot 151",
      "value": "Plot 151",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M802 330.5L817 352.5L800 371L782 352.5L802 330.5Z"
    },
    {
      "uuid": "119",
      "key": "Plot 152",
      "value": "Plot 152",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "5,016",
      "path": "M762 373L783 393L800.5 371L791 361.5L782 352.5L762 373Z"
    },
    {
      "uuid": "120",
      "key": "Plot 153",
      "value": "Plot 153",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M773 401L753.5 383L740.5 397L760 415.5L773 401Z"
    },
    {
      "uuid": "121",
      "key": "Plot 154",
      "value": "Plot 154",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M746.5 430.5L727 412.5L740.5 397L760 415.5L746.5 430.5Z"
    },
    {
      "uuid": "122",
      "key": "Plot 155",
      "value": "Plot 155",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M746.5 430.5L727 412.5L713 428L733 446L746.5 430.5Z"
    },
    {
      "uuid": "123",
      "key": "Plot 156",
      "value": "Plot 156",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M702.5 469.5V439L713 428L733 446L711 469.5H702.5Z"
    },
    {
      "uuid": "124",
      "key": "Plot 157",
      "value": "Plot 157",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M702.5 469.5V439L673 440V461.5L684.5 469.5H702.5Z"
    },
    {
      "uuid": "125",
      "key": "Plot 158",
      "value": "Plot 158",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M645 459H665L673 461V440H658.702H645V459Z"
    },
    {
      "uuid": "126",
      "key": "Plot 159",
      "value": "Plot 159",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M617 460.5L645 459V440H630.702H617V460.5Z"
    },
    {
      "uuid": "127",
      "key": "Plot 160",
      "value": "Plot 160",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M589 462L617 460.5V440H602.702H589V462Z"
    },
    {
      "uuid": "128",
      "key": "Plot 161",
      "value": "Plot 161",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M769 424.5L789 440L782 449.5L751.5 445L769 424.5Z"
    },
    {
      "uuid": "129",
      "key": "Plot 162",
      "value": "Plot 162",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M769 424.5L789 440L801 425.5L782 410L769 424.5Z"
    },
    {
      "uuid": "130",
      "key": "Plot 163",
      "value": "Plot 163",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M801 425.5L782 449.5L803.5 453L819.5 441.5L801 425.5Z"
    },
    {
      "uuid": "131",
      "key": "Plot 164",
      "value": "Plot 164",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M790.5 401L812 419.5L827.5 403L806.5 383L790.5 401Z"
    },
    {
      "uuid": "132",
      "key": "Plot 165",
      "value": "Plot 165",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M824.5 364L842.5 387L827.5 403L806.5 383L824.5 364Z"
    },
    {
      "uuid": "133",
      "key": "Plot 166",
      "value": "Plot 166",
      "status": "Booked",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M860.5 415L842.5 387L812.5 419.5L830 435L860.5 415Z"
    },
    {
      "key": "Hanging Resort",
      "value": "Hanging Resort",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/hanging-resort.jpg",
      "path": "M407 579.5L492.5 563.5L534 516.5V509H407V579.5Z"
    },
    {
      "key": "Swimming Pool",
      "value": "Swimming Pool",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/swimming-pool.jpg",
      "path": "M515 494H467L476 483H515V494Z"
    },
    {
      "key": "Restaurant",
      "value": "Restaurant",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/restaurant.jpg",
      "path": "M515 483H476V456H515V483Z"
    },
    {
      "key": "Banquet Hall",
      "value": "Banquet Hall",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/banquet-hall.jpg",
      "path": "M456 483H435V456H456C451.527 466.2 451.79 472.132 456 483Z"
    },
    {
      "key": "Indoor Games",
      "value": "Indoor Games",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/indoor-games.jpg",
      "path": "M429 489H407V449H429V489Z"
    },
    {
      "key": "Box Cricket",
      "value": "Box Cricket",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/box-cricket.jpg",
      "path": "M515 443H487V432H515V443Z"
    },
    {
      "key": "Volley Ball Court",
      "value": "Volley Ball Court",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/volley-ball-court.jpg",
      "path": "M484 443H464V432H484V443Z"
    },
    {
      "key": "Lawn Tennis",
      "value": "Lawn Tennis",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/lawn-tennis.jpg",
      "path": "M460 443H433V432H460V443Z"
    }
  ],
  "Variants" : [
    {
      "uuid": "1",
      "key": "villa2",
      "value": "2 Bed Villa",
      "price": "79L",
      "area": "3,000",
      "carpetArea": "850",
      "configuration": "2 Bed Villa",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/villa-2-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/villa-2-floorplan.jpg"
    },
    {
      "uuid": "2",
      "key": "villa3",
      "value": "3 Bed Villa",
      "price": "1.36Cr",
      "area": "3,000",
      "carpetArea": "1,200",
      "configuration": "3 Bed Villa",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/villa-3-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/villa-3-floorplan.jpg"
    },
    {
      "uuid": "3",
      "key": "villa4",
      "value": "4 Bed Villa",
      "price": "1.26Cr",
      "area": "3,000",
      "carpetArea": "1,500",
      "configuration": "4 Bed Villa",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/villa-4-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/villa-4-floorplan.jpg"
    },
    {
      "uuid": "4",
      "key": "villa5",
      "value": "5 Bed Villa",
      "price": "1.04Cr",
      "area": "3,000",
      "carpetArea": "1,200",
      "configuration": "5 Bed Villa",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/villa-5-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/villa-5-floorplan.jpg"
    }
  ],
}

export const FormSteps = ["Explore Project","Explore Master layout","Select Variant","Enter Details","KYC & Make Payment"]